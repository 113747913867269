<template>
    <main>
        <HeaderTab :title="$t('deplacement.transport')" :swiper_tabs="swiper_tabs" />

		<div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    export default {
		name: "Deplacement",
		data () {
			return {
				swiper_tabs: [{
					id: 'deplacement',
					label: 'deplacement.deplacements',
					active: false,
					href: 'DeplacementListe'
				},{
					id: 'suggestion',
					label: 'deplacement.suggestions',
					active: false,
					href: 'DeplacementSuggestion'
				}]
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		},
    }
</script>